import arrw from "../assets/images/hero/arrw.png";
import icon1 from "../assets/images/hero/icon1.png";
import icon2 from "../assets/images/hero/icon2.png";
import icon3 from "../assets/images/hero/icon3.png";
import icon4 from "../assets/images/hero/icon4.png";
import icon5 from "../assets/images/hero/icon5.png";
import icon6 from "../assets/images/hero/icon6.png";
import icon7 from "../assets/images/hero/icon7.png";
import icon8 from "../assets/images/hero/icon8.png";
import apply1 from "../assets/images/main/apply1.png";
import apply2 from "../assets/images/main/apply2.png";
import apply3 from "../assets/images/main/apply3.png";
import apply4 from "../assets/images/main/apply4.png";
import apply5 from "../assets/images/main/apply5.png";
import apply6 from "../assets/images/main/apply6.png";
import icon from "../assets/images/main/icon.png";
import main2 from "../assets/images/main/main2.png";
import main22 from "../assets/images/main/main22.png";
import main3 from "../assets/images/main/main3.png";
import main33 from "../assets/images/main/main33.png";
import main4 from "../assets/images/main/main4.png";
import main44 from "../assets/images/main/main44.png";
import main5 from "../assets/images/main/main5.png";
import main55 from "../assets/images/main/main55.png";
import main6 from "../assets/images/main/main6.png";
import main7 from "../assets/images/main/main7.png";
import main8 from "../assets/images/main/main8.png";
import main9 from "../assets/images/main/main9.png";
import main1vn from "../assets/images/main/main1Vn.png";
import main3vn from "../assets/images/main/main3vn.png";
export const dataHero = [
  {
    id: 1,
    img: icon1,
    title: "HERO7",
    des: "HERO8",
    more: "MORE",
    icon: arrw,
    slug: "https://wasabi.com/backup-and-recovery/",
  },
  {
    id: 2,
    img: icon2,
    title: "HERO9",
    des: "HERO10",
    more: "MORE",
    icon: arrw,
    slug: "https://wasabi.com/archiving/ ",
  },
  {
    id: 3,
    img: icon3,
    title: "HERO11",
    des: "HERO12",
    more: "MORE",
    icon: arrw,
    slug: "https://wasabi.com/cloud-data-migration/",
  },
  {
    id: 4,
    img: icon4,
    title: "HERO13",
    des: "HERO14",
    more: "MORE",
    icon: arrw,
    slug: "",
  },
  {
    id: 5,
    img: icon5,
    title: "HERO15",
    des: "HERO16",
    more: "MORE",
    icon: arrw,
    slug: "https://wasabi.com/media-workflows/",
  },
  {
    id: 6,
    img: icon6,
    title: "HERO17",
    des: "HERO18",
    more: "MORE",
    icon: arrw,
    slug: "https://wasabi.com/surveillance/ ",
  },
  {
    id: 7,
    img: icon7,
    title: "HERO19",
    des: "HERO20",
    more: "MORE",
    icon: arrw,
    slug: "",
  },
  {
    id: 8,
    img: icon8,
    title: "HERO21",
    des: "HERO22",
    more: "MORE",
    icon: arrw,
    slug: "",
  },
];

export const datahot = [
  {
    id: 1,
    img: main22,
    imgVn: main1vn,
    imgActive: main2,
    title: "ADVACE1",
    icon: icon,
    des: "ADVACE111",
    des1: "ADVACE2",
    des2: "ADVACE3",
    des3: "ADVACE4",
    imgMain: main6,
    key: 1,
  },
  {
    id: 2,
    img: main3,
    imgVn: main3,
    imgActive: main33,
    title: "ADVACE5",
    icon: icon,
    des: "ADVACE555",
    des1: "ADVACE6",
    des2: "ADVACE7",
    imgMain: main7,
    key: 2,
  },
  {
    id: 3,
    img: main4,
    imgVn: main3vn,
    imgActive: main44,
    title: "ADVACE8",
    icon: icon,
    des: "",
    des1: "ADVACE9",
    des2: "ADVACE10",
    imgMain: main8,
    key: 3,
  },
  {
    id: 4,
    img: main5,
    imgVn: main5,
    imgActive: main55,
    title: "ADVACE11",
    icon: icon,
    des: "ADVACE122",
    des1: "ADVACE12",
    des2: "ADVACE13",
    des3: "ADVACE14",
    des4: "ADVACE15",
    imgMain: main9,
    key: 4,
  },
];

export const dataHotImg = [
  {
    id: 1,
    img: main6,
    imgVn: main1vn,
    key: 1,
  },
  {
    id: 2,
    img: main7,
    imgVn: main7,
    key: 2,
  },
  {
    id: 3,
    img: main8,
    imgVn: main3vn,
    key: 3,
  },
  {
    id: 4,
    img: main9,
    imgVn: main9,
    key: 4,
  },
];
export const dataApply = [
  {
    id: 1,
    img: apply1,
    title: "HERO61",
    title1: "",
    des: "HERO62",
    more: "HERO63",
    slug: "trang-detail-1",
  },
  {
    id: 2,
    img: apply2,
    title: "HERO64",
    title1: "",
    des: "HERO65",
    more: "HERO63",
    slug: "trang-detail-2",
  },
  {
    id: 3,
    img: apply3,
    title: "HERO66",
    title1: "",
    des: "HERO67",
    more: "HERO63",
    slug: "trang-detail-3",
  },
  {
    id: 4,
    img: apply4,
    title: "HERO68",
    title1: "",
    des: "HERO69",
    more: "HERO63",
    slug: "trang-detail-4",
  },
  {
    id: 5,
    img: apply5,
    title: "HERO70",
    title1: "",
    des: "HERO71",
    more: "HERO63",
    slug: "trang-detail-5",
  },
  {
    id: 6,
    img: apply6,
    title: "HERO72",
    title1: "",
    des: "HERO73",
    more: "HERO63",
    slug: "trang-detail-6",
  },
];
