import { StyleProvider, createCache } from "@ant-design/cssinjs";
import Head from "next/head";
import { useEffect, useState } from "react";
import FooterPresenter from "../components/Footer/Footer.presenter";
import Homepage from "../components/Homepage/Homepage.presenter";
import Menu from "../components/Menu/Menu.presenter";
import "../i18n/index";
import Script from "next/script";
export default function Home() {
  const [isSSR, setIsSSR] = useState(true);
  const cache = createCache();
  useEffect(() => {
    setIsSSR(false);
  }, []);
  return (
    <>
      <Head>
        <title>Wasabi - QD.Tek Distribution Partner</title>
        <meta name="description" content="wasabi" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        {/* <Script
          dangerouslySetInnerHTML={{
            __html: `
              (function() {
                var script = document.createElement('script');
                script.src = "//fw-cdn.com/10939754/3699953.js";
                script.chat = true;
                script.widgetId = "437fc269-e6f7-4e57-bb45-9c1fcda8ee78";
                document.body.appendChild(script);
              })();
            `,
          }}
        ></Script> */}
      </Head>
      <main>
        {!isSSR && (
          <>
            <Menu />
            <Homepage />
            <FooterPresenter />
          </>
        )}
      </main>
    </>
  );
}
