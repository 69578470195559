import new1 from "../assets/images/news/new.webp";
import new2 from "../assets/images/news/news2.webp";
import new3 from "../assets/images/news/news3.webp";
import new4 from "../assets/images/news/news4.webp";
import new5 from "../assets/images/news/news5.webp";
import new6 from "../assets/images/news/news6.webp";
import new7 from "../assets/images/news/news7.webp";
import new8 from "../assets/images/news/news8.webp";

export const news = [
  {
    id: 1,
    title: "NEWS8",
    img: new1,
    des: "NEWS9",
  },
];

export const newsCate = [
  {
    id: 1,
    day: "25",
    month: "NEWS3",
    title: "NEWS1",
    des: "NEWS2",
  },
  {
    id: 2,
    day: "22",
    month: "NEWS3",
    title: "NEWS4",
    des: "NEWS5",
  },
  {
    id: 3,
    day: "19",
    month: "NEWS3",
    title: "NEWS6",
    des: "NEWS7",
  },
];

export const newsPage = [
  {
    id: 1,
    day: "25 tháng 7, 2023",
    img: new2,
    title:
      "Media & Entertainment Organisations Flock to the Cloud but Cite API Fee Challenges and Cybersecurity as Key Concerns",
    slug: "trang detail",
  },
  {
    id: 3,
    day: "25 tháng 7, 2023",
    img: new3,
    title:
      "Wasabi Technologies Unveils Wasabi Cloud Sync Manager, Giving Companies the Power to Escape Vendor Lock In Without Egress Fees or Downtime",
    slug: "trang detail",
  },
  {
    id: 3,
    day: "25 tháng 7, 2023",
    img: new4,
    title:
      "Wasabi Technologies Expands APAC Leadership with Southeast Asia Country Manager to Further Grow Partner and Customer Adoption of Hot Cloud Storage",
    slug: "trang detail",
  },
  {
    id: 1,
    day: "25 tháng 7, 2023",
    img: new4,
    title:
      "Media & Entertainment Organisations Flock to the Cloud but Cite API Fee Challenges and Cybersecurity as Key Concerns",
    slug: "trang detail",
  },
  {
    id: 2,
    day: "25 tháng 7, 2023",
    img: new5,
    title:
      "Wasabi Technologies Unveils Wasabi Cloud Sync Manager, Giving Companies the Power to Escape Vendor Lock In Without Egress Fees or Downtime",
    slug: "trang detail",
  },
  {
    id: 3,
    day: "25 tháng 7, 2023",
    img: new3,
    title:
      "Wasabi Technologies Expands APAC Leadership with Southeast Asia Country Manager to Further Grow Partner and Customer Adoption of Hot Cloud Storage",
    slug: "trang detail",
  },
  {
    id: 1,
    day: "25 tháng 7, 2023",
    img: new5,
    title:
      "Media & Entertainment Organisations Flock to the Cloud but Cite API Fee Challenges and Cybersecurity as Key Concerns",
  },
  {
    id: 2,
    day: "25 tháng 7, 2023",
    img: new6,
    title:
      "Wasabi Technologies Unveils Wasabi Cloud Sync Manager, Giving Companies the Power to Escape Vendor Lock In Without Egress Fees or Downtime",
    slug: "trang detail",
  },
  {
    id: 3,
    day: "25 tháng 7, 2023",
    img: new7,
    title:
      "Wasabi Technologies Expands APAC Leadership with Southeast Asia Country Manager to Further Grow Partner and Customer Adoption of Hot Cloud Storage",
    slug: "trang detail",
  },
  {
    id: 1,
    day: "25 tháng 7, 2023",
    img: new7,
    title:
      "Media & Entertainment Organisations Flock to the Cloud but Cite API Fee Challenges and Cybersecurity as Key Concerns",
    slug: "trang detail",
  },
  {
    id: 2,
    day: "25 tháng 7, 2023",
    img: new6,

    title:
      "Wasabi Technologies Unveils Wasabi Cloud Sync Manager, Giving Companies the Power to Escape Vendor Lock In Without Egress Fees or Downtime",
    slug: "trang detail",
  },
  {
    id: 3,
    day: "25 tháng 7, 2023",
    img: new8,
    title:
      "Wasabi Technologies Expands APAC Leadership with Southeast Asia Country Manager to Further Grow Partner and Customer Adoption of Hot Cloud Storage",
    slug: "trang detail",
  },
];
